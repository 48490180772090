export class ZoomElement {
  constructor(elementSelector) {
    this.element = document.querySelector(elementSelector);

    if (!this.element) throw new Error(`Элемента с селектором ${elementSelector} не существует`);
  }

  zoomElement = (event) => {
    const { offsetX, offsetY } = event;
    this.element.style.transformOrigin = `${offsetX}px ${offsetY}px`;
  }

  resetElement = () => {
    this.element.style.transformOrigin = '';
  }

  init = () => {
    this.element.addEventListener('mousemove', this.zoomElement);

    this.element.addEventListener('mouseleave', this.resetElement);
  }

  unmount = () => {
    this.element.removeEventListener('mousemove', this.zoomElement);
    this.element.removeEventListener('mouseleave', this.resetElement);
  }
}
